import { getConfig } from "../../lib/configMgr";

export const validateUsername = username => {
  if (!username) {
    return "Email required";
  }
  // This expression is from AWS for usernames: [\p{L}\p{M}\p{S}\p{N}\p{P}]+
  // We're more restrictive, at least initially
  if (!username.toLowerCase().match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
    return "Invalid email";
  }
  const parts = username.split('@');
  const domain = parts[parts.length-1];
  const allowedDomains = getConfig()['domains'] || [];
  if (allowedDomains.length > 0 && !allowedDomains.includes(domain)) {
    return "Invalid email";
  }
  return false;
};

export const validatePassword = pw => {
  // Size constraints should match those in Cognito
  const MIN_PW_LENGTH = 8;
  const MAX_PW_LENGTH = 256;
  const NL = "\n";
  let error = "";
  let br = "";
  if (!pw) {
    return "Password required";
  }
  if (pw.match(/\s/)) {
    error += br + "Password may not include whitespace";
    br = NL;
  }
  if (!pw.match(/[a-z]/)) {
    error += br + "Password must include a lowercase letter";
    br = NL;
  }
  if (!pw.match(/[A-Z]/)) {
    error += br + "Password must include an uppercase letter";
    br = NL;
  }
  if (!pw.match(/[0-9]/)) {
    error += br + "Password must include a digit";
    br = NL;
  }
  if (!pw.match(/[^a-zA-Z0-9]/)) {
    error += br + "Password must include a non-alphanumeric";
    br = NL;
  }
  if (pw.length < MIN_PW_LENGTH) {
    error += br + "Password must be at least " + MIN_PW_LENGTH + " characters";
    br = NL;
  }
  if (pw.length > MAX_PW_LENGTH) {
    error += br + "Password may not exceed " + MAX_PW_LENGTH + " characters";
    br = NL;
  }
  return error || false;
};

// Returns a state object indicating errors (if any) for each field
export const validateForm = (username, password, passwordCheck) => {
  const u = validateUsername(username);
  const p = validatePassword(password);
  const cp = password !== passwordCheck ? "Passwords do not match" : false;
  return {
    username: u,
    password: p,
    passwordCheck: cp,
    submit: u || p || cp
  };
};

export const validateFormExt = (
  username,
  verificationCode,
  oldPassword,
  newPassword,
  newPasswordCheck,
  newPasswordInvalid,
  validating
) => {
  const passwordChange = verificationCode === null;
  const u = validateUsername(username);
  const p = validatePassword(newPassword);
  const cp =
    newPassword !== newPasswordCheck ? "Passwords do not match" : false;
  const vc =
    !passwordChange && !verificationCode
      ? "Verification code is required"
      : false;
  const op = passwordChange && !oldPassword ? "Old password required" : false;

  return {
    username: u,
    verificationCode: vc,
    oldPassword: op,
    newPassword: p,
    newPasswordCheck: cp,
    submit:
      u ||
      vc ||
      op ||
      p ||
      cp ||
      newPasswordInvalid !== false ||
      (passwordChange && validating)
  };
};
