import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {toJS} from "mobx";
import {toast} from "react-toastify";
import {fetchChatCompletion, fetchHelp, oneshot} from "../queries/autosuggest";
import LoadingAnimation from "./LoadingAnimation";
import Textarea from 'react-expanding-textarea';
import {DEV, getConfig} from "../lib/configMgr";
import {useTypewriter} from "../hooks/useTypewriter";
import {useSSEListener} from "../hooks/useSSEListener";
import Avatar from "./Avatar";
import CopyToClipboardButton from "./CopyToClipboardButton";
import {MD_PROMPT, TEXT_PLACEHOLDER} from "../templates/slides";
import contentDisposition from "content-disposition";
import {
  captureMessage,
  fixLLMMarkdown,
  formatDate,
  isImage,
  isPlexID,
  linkifyPlexIDs,
  RE_PLEX_ID,
  saveBlobContent,
  stylizePublications
} from "../lib/utils";
import {blunt, extractSearchParams, invokeTool, TOOL_ANALYST, TOOL_MEMBERS, TOOL_RESOLVE,} from "../tools";
import {renderMarkdown} from "../markdown/markdown-factory";
import SubmitSearchButton from "./SubmitSearchButton";
import {ChatSettings} from "./ChatSettings";
import SearchContext from "../lib/SearchContext";
import _ from "lodash";
import {useDebounce} from "../hooks/useDebounce";
import {useInterval} from "../hooks/useInterval";
import SimpleSelect from "./controls/SimpleSelect";
import FileAttachments from "./controls/FileAttachments";
import DownloadButton from "./DownloadButton";

const { ExternalRESTAPI, env } = getConfig();
const WELCOME = "Welcome to the Plex LLM!";
const INPUT_STORAGE_KEY = "chat-prompt";
const HELP_STORAGE_KEY = "chat-help"
const SCROLL_FUDGE = 0;

const DEBUG_STREAM = true;

// Suffixes recognized by bedrock; assume text if not in one of these
// Claude also accepts odt, rtf, epub, and json; pass these as "txt"
// See https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_Message.html
// See https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_DocumentBlock.html
// See https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_ImageBlock.html
const FILE_INPUT_ACCEPT = [
  ".pdf", "application/pdf",
  ".csv", "text/csv",
  ".doc", "application/msword",
  ".docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".xls", "application/vnd.ms-excel",
  ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".html", "text/html",
  ".txt", "text/plain",
  ".md", "text/markdown",
  ".png", "image/png",
  ".jpeg", "image/jpeg",
  ".gif", "image/gif",
  ".webp", "image/webp",
];
const MIME2FORMAT = {
  "application/pdf": "pdf",
  "text/csv": "csv",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "text/html": "html",
  "text/plain": "txt",
  "text/markdown": "md",
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpg": "jpeg",
  "image/gif": "gif",
  "image/webp": "webp",
};
const DOC_LIMIT = 5;
const DOC_SIZE_LIMIT = 4.5 * 1024 * 1024;
const IMAGE_LIMIT = 20;
const IMAGE_SIZE_LIMIT = 3.75 * 1024 * 1024;

// Canonical LLM conversation roles
const ROLE_SYSTEM = "system";
const ROLE_USER = "user";
const ROLE_ASSISTANT = "assistant";

// Available "personalities"
export const ROLE_GUIDE = "guide";
export const ROLE_ANALYST = "search-analyst";
// Used for any "background" tool use
export const ROLE_TOOL_RESULT = "tool-result";

const appendModel = (model) => {
  return DEV ? ` (${model})` : "";
};

export const extractVersion = (model) => {
  if (/-v\d+/.test(model)) {
    return ` (${model.replace(/^.*?-(v.*)$/, "$1")})`;
  }
  return "";
};

const decorateAssistantText = (s) => {
  if (!s) {
    return "";
  }
  // Simply replacing words can result in some really odd sentences...
  //return s.replace(/(anthropic|openai)/gi, "Plex").replace(/(claude|chatgpt)/gi, "Plexy");
  return s;
};


const renderLLMMarkdown = (output) => {
  const markdown = fixLLMMarkdown(blunt(output));
  const overrides = {
    ["llm-training"]: {
      component: ({children}) => (<span className={"llm-training"} >{children}</span>),
    },
    thinking: {
      component: ({children}) => (<span className={"thinking super-user"} >{children}</span>),
    }
  };
  return renderMarkdown({markdown, overrides})
};


const canonicalMessages = messages => {
  return messages
    .map(({role, content}) => ({role: role === "tool" ? ROLE_USER : role, content}))
    .filter(({role}) => role === ROLE_USER || role === ROLE_ASSISTANT);
};

export const ChatSession = ({className = null, userStore, appStatusStore, onSubmit = null, showWelcome = false,
                              autoFocus = false, onSearchClick = null, onConversationActive = null,
                              searchParams = null, categories = null, showSettings = true,
                              useTools = false, showToolResults = true, useMarkdown = true,
                              fetchSuggestions = null, fetchEntities = null, waitingForResults = false,
                              allowAttachments = false, assistantRole = ROLE_GUIDE,
                              tooltipID = "tt"}) => {

  const [sessionID, setSessionID] = useState(null);
  const helpOnly = !useTools && !searchParams;
  // Preserve chat context for the duration of the app status store; distinguish between chat contexts
  // help, guide, and agents
  const settingsKey = helpOnly
                     ? HELP_STORAGE_KEY
                     : `chat-session${sessionID ? '-' + sessionID : ''}-${JSON.stringify(searchParams)}`;

  const {aiModels = {}, aiModelDefault = null, aiTemplates = {}, dsConfig = 0} = appStatusStore;
  const {isAdmin, isSuperUser, showAdmin, authData: auth, userName} = userStore;
  const initialInput = helpOnly ? "" : localStorage[INPUT_STORAGE_KEY] || "";
  const [interrupted, setInterrupted] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const showAdvancedFeature = (isAdmin && showAdmin) || isSuperUser;

  const inputRef = useRef();
  const convoTailRef = useRef();

  const [expandedSourceLinks, setExpandedSourceLinks] = useState({});
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const streamResponse = DEV && DEBUG_STREAM;
  const [convo, setConvo] = useState(toJS(appStatusStore.settings.get(settingsKey, []) || []));
  // Must be updated when convo is updated
  // FIXME keeping a separate list is error prone, but I doubt we can just use useEffect here to keep chatMessages updated
  const chatMessages = useMemo(() => canonicalMessages(convo), []);
  const convoStarted = convo.length > 0;
  const [inputText, setInputText] = useState(initialInput);
  const [streamURL, setStreamURL] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [chatSettings, setChatSettings] = useState({});
  // Allow async methods to dynamically check for user interrupt
  const interruptRef = useRef();
  // Allow async methods to dynamically check for current message
  const pendingChatRequestRef = useRef();
  const pendingToolUseRequestsRef = useRef();
  const [typingElement, setTypingElement] = useState(null);
  const pendingIDLookups = useMemo(() => new Set(), []);
  const failedIDLookups = useMemo(() => new Set(), []);
  const editedTemplates = useMemo(() => ({}), []);
  const [selectedTemplate, setSelectedTemplate] = useState("extra-prompt");
  const [selectedAIModel, setSelectedAIModel] = useState(aiModelDefault);
  const [templateText, setTemplateText] = useState(aiTemplates['extra-prompt']);
  const [typewriterText, startTyping] = useTypewriter();
  const convoRef = useRef();
  const [attachments, setAttachments] = useState([]);

  const busy = waitingForResponse || isStreaming;

  const showConversation = onSubmit == null && convo.length > 0;
  const userInputPlaceholder = convoStarted
                               ? (busy ? "Plexy is thinking..." : "Reply to Plexy...")
                               : searchParams
                                 ? (waitingForResults ? "Waiting for search results..." : "Discuss the search results")
                                 : helpOnly
                                   ? "How may I help you?"
                                   : "Ask a question, start an investigation, or learn about Plex";

  useEffect(() => {
    console.log(`Chat settings: ${JSON.stringify(chatSettings)}`);
  }, [chatSettings]);

  useEffect(() => {
    setTemplateText(editedTemplates[selectedTemplate] || aiTemplates[selectedTemplate]);
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateText !== aiTemplates[selectedTemplate]) {
      editedTemplates[selectedTemplate] = templateText;
    }
    else {
      delete editedTemplates[selectedTemplate]
    }
  }, [templateText]);

  useEffect(() => {
    interruptRef.current = interrupted;
  }, [interrupted]);

  useEffect(() => {
    autoscroll();
  }, [typewriterText]);

  const resolveSourceLinks = plexIDs => {
    if (fetchEntities) {
      // Keep track of individual failures and don't retry
      const activeLookups = new Set(plexIDs);
      fetchEntities(plexIDs)
        .then(entities => {
          if (convo.length === 0) return;
          const updated = entities.reduce((result, el) => {
            if (el.links?.length) {
              result[el.id] = el.links[0].url;
            }
            else {
              failedIDLookups.add(el.id);
            }
            activeLookups.delete(el.id);
            pendingIDLookups.delete(el.id);
            return result;
          }, {});
          setExpandedSourceLinks((prev) => ({...prev, ...updated}));
          Array.from(activeLookups).forEach(id => failedIDLookups.add(id));
        })
        .catch(error => {
          if (convo.length === 0) return;
          console.warn("Failed to resolve some markdown links", plexIDs, error);
        });
    }
  };

  const interpolateSourceLinks = (s) => {
    const missing = [];
    // Replace any standalone "ID" with [ID](ID)
    const linkifiedText = stylizePublications(linkifyPlexIDs(s));
    // Replace any "](ID)" with "](SOURCE_LINK "ID")"
    const mdLinks = linkifiedText.replace(/(?<=[^\\]]\()([^)]+)(?=\))/mg, (match, id) => {
      if (/^https?:\/\//.test(id)) {
        return id;
      }
      if (expandedSourceLinks[id]) {
        return `${expandedSourceLinks[id]} "${id}"`;
      }
      if (RE_PLEX_ID.test(id)) {
        missing.push(id);
      }
      return match;
    });
    if (missing.length) {
      const unfetched = missing.filter(id => !pendingIDLookups.has(id) && !failedIDLookups.has(id));
      if (unfetched.length > 0) {
        unfetched.forEach(id => pendingIDLookups.add(id));
        console.debug("Resolving", unfetched);
        resolveSourceLinks(unfetched);
      }
    }
    return mdLinks;
  };

  const autoscroll = (requestFocus = true) => {
    // scroll to bottom unless user is scrolling
    if (!userScrollRef.current) {
      scrollToBottom(requestFocus);
    }
  }

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    appStatusStore.settings.set(settingsKey, convo || []);
    onConversationActive && onConversationActive(convo.length > 0);
    if (convo.length && convo[convo.length-1].content.some(contentBlock => contentBlock.status === "error")) {
      captureMessage("Tool use error", "error", getClipboardContents(true));
    }
  }, [convo]);

  const queueChatRequest = (request) => {
    if (request && pendingChatRequestRef.current && pendingChatRequestRef.current !== request) {
      console.error("Overwriting previous request before last has completed",
                    pendingChatRequestRef.current, request);
    }
    pendingChatRequestRef.current = request;
    if (request) {
      sendChatMessage(request);
    }
  };

  const queueToolUseRequests = (requests) => {
    const unresolved = pendingToolUseRequestsRef.current;
    pendingToolUseRequestsRef.current = requests;
    if (requests) {
      if (unresolved && unresolved !== requests) {
        console.error("Overwriting previous tool use request before last has completed",
                      unresolved, requests);
      }
      setWaitingForResponse(true);
      processToolUseRequests(requests).then(handleToolUseCompletion);
    }
  };

  const sendChatMessage = (chatMessage) => {
    setWaitingForResponse(true);
    scrollToBottom(true);
    let promise = null;
    chatMessages.push({...chatMessage, role: ROLE_USER});
    if (helpOnly) {
      promise = fetchHelp(chatMessage.content.map(el => el.text || "").join(""), sessionID && `${sessionID}:${chatMessages.length - 1}`);
    }
    else {
      // FIXME account for system messages
      if (!chatMessages.every((el, idx) => el.role === ((idx % 2 === 0) ? ROLE_USER : ROLE_ASSISTANT))) {
        // FIXME throwing an error here isn't the right solution
        try {
          console.error(`Badly formatted messages: ${JSON.stringify(chatMessages)}`);
        }
        catch (e) {
          console.error(`Badly formatted messages: ${JSON.stringify(chatMessages.map(el => ({role: el.role, content: el.content.map(b => Object.keys(b))})))}`)
        }
        throw new Error("Badly formatted messages");
      }
      promise = fetchChatCompletion(searchParams || {},
                                    {
                                      model: selectedAIModel,
                                      ...chatSettings,
                                      stream: streamResponse,
                                      messages: chatMessages,
                                      templates: editedTemplates,
                                      useMarkdown,
                                      useTools,
                                      role: assistantRole,
                                    }, auth)
    }
    setConvo(convo => [...convo, {...chatMessage, ts: Date.now()}]);

    promise
      .then(result => {
        if (pendingChatRequestRef.current === chatMessage) {
          handleChatCompletion(result);
        }
        else {
          console.warn("Ignore obsolete chat response", chatMessage, pendingChatRequestRef.current);
        }
      })
      .catch(e => {
        if (pendingChatRequestRef.current === chatMessage) {
          handleChatCompletionError(e, ROLE_SYSTEM);
        }
        else {
          console.warn("Ignore obsolete chat response error", e, chatMessage, pendingChatRequestRef.current);
        }
      })
      .finally(() => {
        if (pendingChatRequestRef.current === chatMessage) {
          pendingChatRequestRef.current = null;
        }
        autoscroll();
      });
  };

  useEffect(() => {
    if (!busy) {
      const id = setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
      return () => clearTimeout(id);
    }
  }, [busy])

  const handleToolUseCompletion = (requests, interrupted = false) => {
    console.debug("handleToolUseCompletion", requests);
    // Ignore obsolete responses
    if (requests && pendingToolUseRequestsRef.current && !_.isEqual(pendingToolUseRequestsRef.current, requests)) {
      console.log("Ignore obsolete tool results", pendingToolUseRequestsRef.current, requests);
      return;
    }
    pendingToolUseRequestsRef.current = null;
    const toolResults = [];
    const toolUseInfo = {};
    requests.forEach(request => {
      const {result = null, ...toolUse} = request;
      const error = result?.error || request.error;
      const resultBlock = {};
      //console.debug(`handleToolUseCompletion (${toolUse.name})`, result);
      toolUseInfo[toolUse.toolUseId] = toolUse;
      if (interrupted) {
        console.warn("User interrupt");
        resultBlock.text = `
The user has more input.  Finish your current response and end your turn.  Do not generate additional tool use requests.
Acknowledge receipt of this instruction by saying 'Do you have something to add?'    
`;
      }
      else if (error) {
        console.error(`Tool use resulted in an error ${error}`);
        resultBlock.json = {error: `${error}`};
      }
      else if (toolUse.name === TOOL_ANALYST) {
        const {role, search_id: searchID = null} = result;
        // search-analyst results include internal messaging blocks in "content"
        // include these in the conversation, but change their roles
        // so that they are ignored by the primary conversation
        const analystMessages = result?.content;
        const lastMsg = analystMessages[analystMessages.length - 1]
        setConvo(convo => [
          ...convo, ...injectToolUseInfo(analystMessages)
            .map(el => ({...el, ts: Date.now(), role: el.role === ROLE_USER ? `${role}-result` : role}))
        ]);
        resultBlock.json = {text: lastMsg.content.map(el => el.text || "").join(""), searchID};
      }
      else if (toolUse.name === TOOL_RESOLVE) {
        const MAX_AS_RESULTS = 500
        const reduced = toolUse.input.terms.length === 1 ? result?.slice(0, MAX_AS_RESULTS) : result;
        // Flatten the data and avoid recursion or duplication of options data
        // Be conservative in what is passed on to the LLM to avoid flooding its context buffer
        const prune = ({entity = null, ...option}) => {
          if (entity) {
            const {dataset = null, inchi = null, inchikey = null, iupac = null, source_id = null, ...pruned} = entity;
            return {
              ...pruned,
              match_term: option['match-term'],
              match_type: option['match-type'],
              match_score: option['match-score']
            };
          }
          return {error: `Could not resolve term '${option['match-term']}'`};
        }
        resultBlock.json = {options: reduced?.map(option => prune(option))};
      }
      else {
        resultBlock.json = result;
      }
      const contentBlock = {
        toolResult: {
          toolUseId: toolUse.toolUseId,
          content: [resultBlock],
          // NOTE: bedrock+claude requires the 'status' == "error"|"success"
          status: error && "error" || "success",
        }
      };
      toolResults.push(contentBlock)
    });

    // Attach tool use info to the tool results message
    // so that we can refer to input parameters and tool name
    queueUserMessageContent(toolResults,"tool", toolUseInfo);
  };

  // FIXME not sure why useCallback is required, but otherwise this fn is considered changed mid-SSE stream
  // This is the case even when useSSEListener is using refs inside constant functions
  const handleStreamingResponse = useCallback(event => {
    //console.log(`handleStreamingResponse`, event);
    const msg = event.data ? JSON.parse(event.data) : {};
    const mtype = msg.type || event.type;
    const lastAssistantTurn = convo => convo.reduce((result, turn) => {
      return turn['role'] === ROLE_ASSISTANT ? turn : result;
    }, null);

    //console.log(`SSE data received`, event);
    // NOTE: these streaming message types correspond to the results of bedrock's converse_stream API
    if (mtype === "message_start") {
      //{"type": "message_start","message": {"id": "msg_01GnRNG1psEzTMECgZr32tiv","content": [],"model": "claude-3-sonnet-28k-20240229","role": "assistant","stop_reason": null,"stop_sequence": null,"type": "message","usage": {"input_tokens": 11414,"output_tokens": 1}}}
      const turn = {role: msg.role || ROLE_ASSISTANT, ts: Date.now(), content: [{text: ""}]};
      setConvo(convo => [...convo, turn]);
      chatMessages.push(turn);
    }
    else if (mtype === "content_block_start") {
      // {"content_block": {"text": "", "type": "text"}, "index": 0, "type": "content_block_start"}
      if (msg.content_block?.text) {
        console.warn("Unexpected content in content_block_start")
      }
    }
    else if (mtype === "content_block_delta") {
      // {"delta": {"text": " that", "type": "text_delta"}, "index": 0, "type": "content_block_delta"}
      if (msg.delta?.text) {
        setConvo(convo => {
          const last = lastAssistantTurn(convo);
          last.content[0].text += msg.delta.text;
          last.ts = Date.now();
          return [...convo];
        });
      }
    }
    else if (mtype === "content_block_stop") {
      // {"type": "content_block_stop", "index": 0}
      if (msg.tool_use) {
        setConvo(convo => {
          const last = lastAssistantTurn(convo);
          last.ts = Date.now();
          last.content.push(...msg.tool_use.map(info => ({toolUse: {...info}})));
          queueToolUseRequests(msg.tool_use);
          return [...convo];
        });
      }
    }
    else if (mtype === "message_delta") {
      //{"type": "message_delta","delta": {"stop_reason": "end_turn","stop_sequence": null},"usage": {"output_tokens": 62}}
    }
    else if (mtype === "message_stop") {
      //{"type": "message_stop","amazon-bedrock-invocationMetrics": {"inputTokenCount": 11414,"outputTokenCount": 62,"invocationLatency": 5665,"firstByteLatency": 3598}}
      setIsStreaming(false);
      setStreamURL(null);
      setConvo(convo => {
        const last = lastAssistantTurn(convo);
        last.ts = Date.now();
        last.stopReason = msg.stop_reason;
        return convo;
      });
      setWaitingForResponse(msg.stop_reason === "tool_use");
    }
    else if (mtype === "ping") {
    }
    else {
      const error = typeof(msg) === "string" ? msg : msg.error || "Connection error";
      toast.error(`LLM error: ${error}`);
      console.error(`Error streaming LLM response: ${error}`);
      setIsStreaming(false);
      setStreamURL(null);
      appendConvoError(error, ROLE_SYSTEM, event);
    }
  }, []);

  const clearFailedIDs = useDebounce(() => {
    //console.log("Clearing failed IDs to retry lookup", failedIDLookups);
    Array.from(failedIDLookups)
      .filter(id => isPlexID(id))
      .forEach(id => failedIDLookups.delete(id));
    //console.log("Cleared failed IDs to retry lookup", failedIDLookups);
  }, 30000, {leading: false, trailing: true});

  useInterval(() => clearFailedIDs(), 30000);

  const scrollToBottom = (requestFocus = false)=> {
    convoTailRef?.current?.scrollIntoView({behavior: "smooth"});
    if (requestFocus) {
      inputRef?.current?.focus();
    }
  };

  useSSEListener({
                   url: streamURL,
                   onMessage: handleStreamingResponse,
                   onError: handleStreamingResponse
                 });

  const inputChanged = (e) => {
    const text = e.target.value || "";
    setInputText(text);
  };

  const clearMessages = () => {
    setWaitingForResponse(false);
    queueChatRequest(null);
    queueToolUseRequests(null);
    setSessionID(null);
    setConvo(convo => convo.length === 0 ? convo : []);
    chatMessages.length = 0;
  };

  const injectToolUseInfo = (messages = []) => {
    // Return a map of tool use ID => tool Use
    const toolUseInfo = messages.reduce((result, el) => {
      el.content.filter(b => b.toolUse).forEach(b => {
        result[b.toolUse.toolUseId] = b.toolUse;
      })
      return result;
    }, {});
    // Attach the map to each message
    return messages.map(el => ({...el, toolUseInfo}));
  };

  const handleChatResponse = (msg) => {
    const toolUseRequests = msg.content?.filter(el => el.toolUse).map(el => ({...el.toolUse})) || [];
    chatMessages.push(msg);
    if (toolUseRequests.length && !interruptRef.current) {
      queueToolUseRequests(toolUseRequests);
    }
    const text = msg.content?.map(el => el.text || "").join("");
    setTypingElement(msg);
    startTyping(text, () => setTypingElement(null));
    setConvo(convo => [...convo, {...msg, ts: Date.now()}]);
    if (toolUseRequests.length && interruptRef.current) {
      sendUserInterrupt(toolUseRequests);
    }
    else {
      interruptRef.current = false;
      setInterrupted(false);
    }
  };

  const handleChatCompletion = resp => {
    setWaitingForResponse(false);
    if (helpOnly) {
      const {response: text, session_id} = resp;
      const msg = {role: ROLE_ASSISTANT, content: [{text}]};
      setSessionID(session_id);
      handleChatResponse(msg);
    }
    else {
      const {
        role,
        content = null,
        stream: streamID = null,
        stop_reason: stopReason = null,
        system_prompt: systemPrompt = null,
        ...adminContent
      } = resp;
      const {usage = {}, metrics = {}} = adminContent;
      setIsStreaming(!!streamID);
      setStreamURL(streamID ? `${ExternalRESTAPI.Stream}?channel=${streamID}` : null);
      // Streaming responses are handled by the SSE handler
      if (!streamID) {
        let msg = {role, content, stopReason, usage, metrics};
        // When talking to the analyst/agent tools, all newly generated messages
        // (including tool use) are returned in "content"; inject all but the last into the conversation,
        // then handle the final message.
        console.log(`handle chat response from ${role}`);
        if (content?.length && content[0]?.content) {
          const updates = content.slice(0, content.length - 1);
          setConvo(convo => [...convo, ...injectToolUseInfo(updates)
            .map(({role, ...el}) => ({
              ...el,
              ts: Date.now(),
              role: role === ROLE_ASSISTANT
                    ? ROLE_ANALYST
                    : role === ROLE_USER
                      ? ROLE_TOOL_RESULT : role}))]);
          msg = content[content.length - 1];
        }
        handleChatResponse(msg);
      }
    }
  };

  const appendConvoError = (text, role = ROLE_SYSTEM, error = null) => {
    console.error(`Chat error (${role})`, error, convo);
    setWaitingForResponse(false);
    setConvo(convo => {
      return [...convo, {role, error: text || error || true, ts: Date.now(), "content": [{text: text || `Chat error ${error}`}]}];
    });
  };

  const handleChatCompletionError = (error, role = ROLE_ASSISTANT) => {
    const text = (typeof(error) === "string" && error
                  || (error instanceof Response && `${error.status}: ${error.statusText}`)
                  || error?.message
                  || error?.content?.map(el => el.text || "").join("")
                  || JSON.stringify(error));

    appendConvoError(text, role, error);
  };

  const formatDocumentContentBlock = (file) => {
    // The attachment will actually be injected server-side from a file upload cache
    const format = MIME2FORMAT[file.type];
    if (!format) {
      console.warn(`File type ${file.type} may not be supported`);
    }
    // Keep a simple object copy of the File object
    const _file = {name: file.name, type: file.type, size: file.size, lastModified: file.lastModified};
    if (isImage(file)) {
      return {image: {format: format || "png", source: {file: _file}}};
    }
    return {document: {format: format || "txt", name: file.name, source: {file: _file}}};
  };

  const formatTextContentBlock = (text) => {
    return {text: text.trim()};
  }

  const queueUserMessageContent = (content, role = ROLE_USER, toolUseInfo = null) => {
    // Avoid directly referring to local state variables (e.g. convo, interrupted)
    // and use useEffect to trigger actual actions based on lastChatRequest
    const userMessage = {role, content, toolUseInfo};
    // user messages and tool results require an up-to-date message history, so trigger within the state update
    queueChatRequest(userMessage);
  };

  const queueUserTextWithAttachments = text => {
    const content = []
    content.push(formatTextContentBlock(text))
    attachments.forEach(f => {
      content.push(formatDocumentContentBlock(f));
    });
    setInputText('');
    setAttachments(_ => []);
    queueUserMessageContent(content);
  }

  const handleSendClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const text = inputText.trim();
    if (text) {
      queueUserTextWithAttachments(text);
    }
  }

  const handlePaste = (e) => {
    try {
      const text = e.clipboardData.getData('Text');
      const parsed = JSON.parse(text);
      // If it renders, it's probably a valid conversation
      renderConversation(parsed);
      setConvo(parsed);
      chatMessages.splice(0, chatMessages.length, ...canonicalMessages(parsed));
      e.preventDefault();
      e.stopPropagation();
      setTimeout(() => scrollToBottom(true), 0);
    }
    catch(e) {
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !(e.shiftKey || e.altKey)) {
      e.stopPropagation();
      e.preventDefault();
      const text = inputText.trim();
      if (text && !busy && !waitingForResults) {
        scrollToBottom(true);
        if (onSubmit) {
          onSubmit(text);
        }
        else {
          queueUserTextWithAttachments(text);
        }
      }
    }
  };
  const sendUserInterrupt = (requests) => {
    // Insert an "interrupt" response to any pending tool use requests
    pendingToolUseRequestsRef.current = requests;
    handleToolUseCompletion(requests, true);
  };
  // This gets kinda complicated when continuing a conversation, so we're disabling user interrupts for now
  const handleInterrupt = () => {
    // Handle these two cases
    // A) middle of tool use, ignore pending tool results and respond immediately
    // B) responding to user request, must wait for a response before stopping
    setInterrupted(true);
    if (pendingToolUseRequestsRef.current) {
      const requests = pendingToolUseRequestsRef.current;
      queueToolUseRequests(null);
      sendUserInterrupt(requests);
    }
  };
  const extractConversationText = (role, content, includeToolUse = false) => {
    return content.map(contentBlock => {
      const {text = null, toolUse = [], toolResult = null, json = null} = contentBlock;
      const toolName = toolUse?.name;
      // Ignore these, they're not interesting
      if (toolName === TOOL_MEMBERS || toolName === TOOL_RESOLVE) {
        return "";
      }
      if (role === ROLE_ASSISTANT) {
        if (toolName === TOOL_ANALYST) {
          const url = SearchContext.getAppSearchURL(extractSearchParams(toolUse, dsConfig, {withLabels: true}));
          return `Plexy: ${toolUse.input.instructions}\n\n${url}\n\n`;
        }
        return includeToolUse ? `Plexy: ${text || toolName}\n\n` : "";
      }
      if (toolResult) {
        if (toolResult.content[0]?.json?.options) {
          return `Resolved IDs: ${toolResult.content[0].json.options.filter(el => el).map(el => el.id).join(", ")}\n\n`;
        }
        return includeToolUse ? extractConversationText("Result", toolResult.content, includeToolUse) : "";
      }
      return `${role === ROLE_USER ? "User" : role === ROLE_SYSTEM ? "System" : role}: ${text || JSON.stringify(contentBlock)}\n\n`;
    }).join("");
  };
  const convertConvo = (full = false, includeToolUse = false) => {
    // Replicate visible entries as much as possible
    if (full) {
      return JSON.stringify(convo);
    }
    const text = convo
      .reduce((result, msg) => `${result}\n${extractConversationText(msg.role, msg.content, includeToolUse)}`, "");
    return interpolateSourceLinks(text);
  }

  const getClipboardContents = useCallback((full = false) => {
    return convertConvo(full, showAdvancedFeature);
  }, [convo, interpolateSourceLinks]);

  useEffect(() => {
    if (!helpOnly) {
      try {
        localStorage[INPUT_STORAGE_KEY] = inputText;
      } catch(e) {}
    }
  }, [inputText]);

  const processToolUseRequests = requests => {
    // Obtain tool use results for a list of tool use requests
    // Cf. sendChatMessage
    // Allow parallel invocation
    requests?.forEach(r => {delete r.result;delete r.error; delete r.status});
    return Promise.all(requests.map(request => {
      //console.debug(`Invoke ${request.name} => ${JSON.stringify(request.input)}`);
      return invokeTool(request,
                        {
                          dsConfig, templates: editedTemplates,
                          fetchEntities, fetchSuggestions,
                          auth,
                        })
        .then(result => {request.result = result; return request;})
        .catch(error => {
          request.error = error;
          return request;
        })
    }));
  };

  const discardConversation = (idx = 0)=> {
    pendingChatRequestRef.current = null;
    pendingToolUseRequestsRef.current = null;
    const userInput = convo[idx]['content'].map(el => el.text || '').join('\n');
    setConvo(convo.slice(0, idx));
    chatMessages.splice(idx, chatMessages.length);
    setInputText(userInput);
    scrollToBottom(true);
    if (idx === 0) {
      setSessionID(null);
    }
  };

  const retype = (idx = 0) => {
    if (idx < convo.length) {
      const msg = convo[idx];
      setTypingElement(msg);
      startTyping(msg.content.map(el => el.text || "").join(""), () => setTypingElement(null));
    }
  };

  const replayConversation = (idx = 0)=> {
    // idx is relative to convo, not messages
    const msg = convo[idx];
    interruptRef.current = false;
    pendingChatRequestRef.current = null;
    pendingToolUseRequestsRef.current = null;
    setInterrupted(false);
    setConvo(convo => convo.slice(0, idx));
    chatMessages.splice(0, chatMessages.length, ...canonicalMessages(convo.slice(0, idx)));
    if (msg.role === ROLE_USER) {
      queueUserMessageContent(msg.content);
    }
    else if (msg.stopReason === "tool_use") {
      handleChatResponse(msg);
    }
  };

  const renderConversation = (turns) => {
    //console.log("Render conversation", turns);
    return turns.map((msg, idx) => {
      const {
        role = ROLE_USER,
        content = [],
        toolUseInfo = null,
        usage: {inputTokens = null, outputTokens = null, totalTokens = null} = {},
        ts = null,
      } = msg;
      //console.log(`${role} msg ${JSON.stringify(msg)}`);
      const timestamp = (!helpOnly && ts && <span className={"timestamp"}>{formatDate(ts)}<br/></span>);
      const stopReason = msg?.stop_reason || msg?.stopReason || "message_error";
      const tokenUsage = showAdvancedFeature ? ` ${inputTokens}/${outputTokens}/${totalTokens}` : "";
      if (role === ROLE_ANALYST) {
        return showAdvancedFeature && (<div key={`${role}-${idx}`} className={`super-user ${role} ${ROLE_TOOL_RESULT} turn`}>
          <div key={"text"} title="Analyst response" >
            {renderLLMMarkdown(content.filter(el => el.text).map(el => el.text || '').join('\n'))}
            {stopReason === "max_tokens" ? (<span className="truncated">(truncated response{tokenUsage})</span>) : ""}
          </div>
          <div key={"tool-use"} title="Agent tool use details" className={`super-user ${role}-tool-use tool-use`} >
            {content.filter(el => el.toolUse)
              .map((el, key) => (<em key={key}>{el.toolUse.name} {JSON.stringify(el.toolUse.input)}<br/></em>))}
          </div>
          <div key={"tool-result"} title="Agent tool use result" className={`super-user ${role}-tool-result tool-result`}>
            {content.filter(el => el.toolResult)
              .map((el, key) => el.toolResult.content
                .map((el, ckey) => (
                  <span key={`${key}_${ckey}`} title={JSON.stringify(el.json)} >
                    {JSON.stringify(el.json).slice(0, 64)}...<br/>
                  </span>
                )))}
          </div>
        </div>);
      }
      const blocks = content?.map((contentBlock, cidx) => {
        if (contentBlock.content) {
          contentBlock = contentBlock.content[contentBlock.content.length - 1];
        }
        const {text = "", toolUse = null, toolResult = null, document = null, image = null} = contentBlock;
        const {status = "success"} = toolResult || {};
        const error = msg.error || status === "error";
        const errorClass = error ? " alert alert-error" : "";
        const key = `${idx}.${cidx}`;
        if (!text && !toolUse && !toolResult) {
          return null;
        }
        if (toolUse) {
          if (toolUse.name === TOOL_RESOLVE || toolUse.name === TOOL_MEMBERS) {
            return showAdvancedFeature && (
              <div key={`tool-use-${key}`}
                   title="The guide is requesting these terms be resolved into Plex IDs"
                   className={`${role} super-user tool-use turn`}>
                <em>{toolUse.name}</em> request {JSON.stringify(toolUse.input)})
              </div>
            );
          }
          if (toolUse.name === TOOL_ANALYST) {
            const searchParams = extractSearchParams(toolUse, dsConfig);
            const instructions = blunt(toolUse.input.instructions || '');
            const titleExtra = text ? `\n\n${instructions}` : '';
            if (typeof(toolUse.input.ids) === "string") {
              toolUse.input.ids = toolUse.input.ids.split(",");
            }
            return (
              <Fragment key={`fragment-${key}`}>
                {showAdvancedFeature && (
                  <div key={`tool-use-${key}`}
                       title="Details of the tool use requested by the guide"
                       className={`${role} turn-${key} super-user${errorClass} tool-use turn`} >
                    <em>{toolUse.name}</em> {JSON.stringify(toolUse.input)})
                  </div>
                )}
                <div key={`tool-use-text-${key}`}
                     title="Instructions from the guide to a sub-agent"
                     className={`${role} turn-${key}${errorClass} tool-use tool-use-text turn`} >
                  {renderLLMMarkdown(instructions)}
                </div>
                <SubmitSearchButton
                  key={`search-button-${key}`}
                  className={`button btn turn-${key}`}
                  searchParams={searchParams}
                  title={`Click to view search results in detail${titleExtra}`}
                  terms={{terms: toolUse.input.ids || [], maxItems: 5}}
                  fetchEntities={fetchEntities}
                />
              </Fragment>
            )
          }
          else {
            // Miscellaneous agent tool use
            return showAdvancedFeature && (
              <div key={`tool-use-${key}`} className={`${role} super-user tool-use turn`}>
                <em>{toolUse.name}</em> request {JSON.stringify(toolUse.input)})
              </div>
            );
          }
        }
        if (role === ROLE_ASSISTANT) {
          const blockText = typingElement === msg ? typewriterText : text;
          const markdown = interpolateSourceLinks(blunt(blockText));
          const isToolUseRequest = stopReason === "tool_use";
          return markdown && (
            <div key={`plexy-${key}`} className={`${role} turn-${key} turn${errorClass}`}>
              {timestamp}
              {renderLLMMarkdown(markdown)}
              {stopReason === "max_tokens" ? (<span className="truncated">(truncated response{tokenUsage})</span>) : ""}
              <span
                key={`retype-${key}`}
                className={`super-user glyphicon glyphicon-repeat flat-button${busy ? ' disabled' : ''}`}
                title={"Re-type last element"}
                onClick={e => retype(idx)}
              ></span>
              {isToolUseRequest && (<span
                key={`replay-${key}`}
                className={`super-user glyphicon glyphicon-refresh flat-button${busy ? ' disabled' : ''}`}
                title={"Replay from here"}
                onClick={e => replayConversation(idx)}
              ></span>)}
            </div>
          );
        }
        if (toolResult) {
          // NOTE: we're ignoring any text (e.g. interrupt) we might have sent with the tool result
          const toolInfo = toolUseInfo[toolResult?.toolUseId];
          const title = error
                        ? `Tool failed${showAdvancedFeature ? ' ' + JSON.stringify(toolResult) : ''}`
                        : toolInfo.name === TOOL_RESOLVE
                          ? toolResult.content[0].json?.options.filter(el => el).map(el => el.id).join(", ")
                          : toolInfo.name === TOOL_ANALYST
                            ? (toolResult.content.map(el => el.text || "").join("\n") || "(no response)")
                            : JSON.stringify(toolResult);
          const className = `${role} turn-${key}${errorClass} tool-result`;
          const text = error && !showAdvancedFeature ? `Server error` : title;
          //console.debug(`Render tool result: ${text}`);
          const toolResultText = stopReason === "user_interrupt" ? "User interrupted" : "Results received for";
          return (
            <Fragment key={key}>
              {showAdvancedFeature || showToolResults && (
                <div key={`tool-result-text-${key}`} title="Agent response" className={`${className} tool-result-text turn`}>
                  {renderLLMMarkdown(text)}
                  {stopReason === "max_tokens" ? (<span className="truncated">(truncated response{tokenUsage})</span>) : ""}
                </div>)}
              {showAdvancedFeature && (
                <div key={`tool-result-${key}`}
                     title={title}
                     className={`super-user ${className}`}>
                  {toolResultText} <em>{toolInfo.name}</em>
                </div>)}
            </Fragment>
          );
        }
        if (role === ROLE_SYSTEM) {
          return (
            <div key={`system-${key}`} className={`${role} turn-${key}${errorClass} turn`}>{text}</div>
          );
        }
        return (
          <div key={`user-${key}`} className={`${role} turn-${key} turn`}>
            {timestamp}
            {role === ROLE_USER ? (<Avatar key={`avatar-${key}`} ts={ts} />) : ""}
            {text.split("\n").map((s, pidx) => (<span key={`user-${key}.${pidx}`}>{s}<br/></span>))}
            {role === ROLE_USER ? (
              <>
                <span
                  key={`discard-${key}`}
                  className={`glyphicon glyphicon-trash flat-button${busy ? ' disabled' : ''}`}
                  title={"Discard from here"}
                  onClick={e => discardConversation(idx)}
                ></span>
                <span
                  key={`replay-${key}`}
                  className={`glyphicon glyphicon-refresh flat-button${busy ? ' disabled' : ''}`}
                  title={"Replay from here"}
                  onClick={e => replayConversation(idx)}
                ></span>
              </>
            ) : ""}
          </div>
        );
      });
      const fileBlocks = content
        .filter(block => block.document || block.image)
        .map(block => block.document || block.image);
      if (fileBlocks.length) {
        //console.log("Found content blocks", fileBlocks);
        const files = fileBlocks.map(block => block.source.file);
        //console.log("Found attachments", files);
        blocks.push((
                      <FileAttachments key="attachments" value={files} readOnly={true} />
                    ));
      }
      return blocks;
    });
  };

  const downloadSummary = e => {
    let filename = null;
    setIsDownloading(true);
    const options = {
      input_format: "markdown",
      output_format: "pptx",
      mime_type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    }
    const inputText = convertConvo();
    oneshot(MD_PROMPT.replace(TEXT_PLACEHOLDER, `<input-text>${inputText}</input-text>`), auth, options)
      .then(response => {
        const header = response.headers.get('content-disposition');
        const disposition = typeof header === "string" ? contentDisposition.parse(header) : null;
        filename = disposition ? disposition.parameters.filename : `summary.pptx`;
        return response.blob();
      })
      .then(blob => {
        saveBlobContent(blob, filename);
      })
      .catch(e => {
        console.error("Summary generation failed", e);
        toast.error("Summary generation failed");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const allowModelSelection = showAdvancedFeature || /^.*?@(aws|amazon\.co(m|\.uk))$/.test(userName);

  // Try to avoid autoscroll fighting with user scroll
  // TODO: encapsulate this into a hook
  const atBottom = el => el.scrollHeight - el.clientHeight - el.scrollTop < 5;
  const grewRef = useRef(false);
  const userScrollRef = useRef(false);

  useEffect(() => {
    if (convoRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(e => {
          if (e.target === convoRef.current) {
            //console.log("Resized");
            grewRef.current = true;
            autoscroll();
          }
        });
      });
      resizeObserver.observe(convoRef.current);
      return () => {
        resizeObserver.disconnect();
      }
    }
  }, []);

  const handleScroll = (e) => {
    if (e.target === convoRef.current) {
      const {scrollTop = 0, scrollHeight = 0, clientHeight = 0} = convoRef.current;
      if (atBottom(convoRef.current)) {
        grewRef.current = userScrollRef.current = false;
      }
      else if (!userScrollRef.current) {
        userScrollRef.current = !grewRef.current;
      }
      //console.log(`Scrolled user=${userScrollRef.current} grew=${grewRef.current} scrollTop=${scrollTop} scrollHeight=${scrollHeight} clientHeight=${clientHeight}`);
    }
  };

  //console.log("Render chat session, attachments", attachments);
  return (
    <div key="session" className={`chat-session${className ? " " + className : ""}`}>
        {showConversation && (
          <div ref={convoRef} key="conversation" className="conversation" onScroll={handleScroll} >
            {showWelcome && (<div key="welcome" className={ROLE_SYSTEM}>{WELCOME}</div>)}
            {renderConversation(convo)}
            <div key="convo-tail"
                 className="convo-tail"
                 ref={convoTailRef}>
            </div>
          </div>
        )}
      {convo.length > 0 && (
        <div key="controls" className={"controls"}>
          <CopyToClipboardButton
            key="controls-copy"
            className={"glyphicon glyphicon-copy"}
            title={"Copy conversation to clipboard"}
            getText={e => getClipboardContents((e.shiftKey || e.altKey) && !helpOnly)}
          />
          {!helpOnly && (
            <>
              <DownloadButton
                key="controls-download-conversation"
                title={"Download conversation"}
                getText={e => getClipboardContents(e.shiftKey || e.altKey)}
                getFilename={e => e.shiftKey || e.altKey ? "plex-conversation.json" : "plex-conversation.txt"}
                getType={e => e.shiftKey || e.altKey ? "application/json" : "text/plain"}
              />
              <DownloadButton
                key="controls-download-summary"
                className={`download-ppt${isDownloading ? " icon-pulse" : ""}`}
                glyphicon={""}
                disabled={convo.length === 0 || isDownloading}
                title={isDownloading ? "Downloading Summary" : "Summarize Results"}
                onClick={downloadSummary}
              />
            </>
          )}
          {isStreaming && (<div className={"glyphicon glyphicon-wireless icon-pulse"}></div>)}
          <LoadingAnimation key="loading-animation" style={{visibility: busy ? "visible" : "hidden"}}/>
          <button
            key="controls-clear"
            className={"glyphicon glyphicon-trash"}
            title={"Clear the conversation"}
            onClick={clearMessages}
          />
        </div>
      )}
      <div key="chat-control" className={`chat-box-control`}>
        <Textarea
          key={"input"}
          className={"prompt"}
          ref={inputRef}
          disabled={waitingForResults}
          placeholder={userInputPlaceholder}
          value={waitingForResults ? "" : inputText || ""}
          onChange={inputChanged}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        />
        <button
          key={"send"}
          className={"send"}
          disabled={!inputText.trim() || busy || waitingForResults}
          title={inputText ? "Send this message to Plexy" : "Type something to send to Plexy"}
          onClick={handleSendClick}
        >
          <span className={"glyphicon glyphicon-play"}></span>
        </button>
        {useTools && false && (
          <button
            key={"stop"}
            className={"stop"}
            disabled={!convo.length || !busy || interrupted || !useTools || waitingForResults}
            title={interrupted ? "Interrupting..." : waitingForResults ? "Interrupt Plexy" : "Plexy is waiting"}
            onClick={() => handleInterrupt()}
          >
            <span className={"glyphicon glyphicon-stop"}></span>
          </button>
        )}
        {onSearchClick && (<button
          key={"chat"}
          className={"chat"}
          title={"Switch to search mode"}
          onClick={onSearchClick}
        >
          <span className={"glyphicon glyphicon-search"}></span>
        </button>)}
      </div>
      {useTools && allowAttachments && (
        <div className={"attachments"}>
          <FileAttachments
            uploadURL={"/api/upload"}
            value={attachments}
            onChange={files => setAttachments(files)}
            authData={userStore}
            documentLimit={DOC_LIMIT}
            documentSizeLimit={DOC_SIZE_LIMIT}
            imageLimit={IMAGE_LIMIT}
            imageSizeLimit={IMAGE_SIZE_LIMIT}
            accept={FILE_INPUT_ACCEPT.join(',')}
          />
          {allowModelSelection && !showSettings && (
            <SimpleSelect
              className={"super-user model-select"}
              options={Object.keys(aiModels)
                .sort((a, b) =>
                        aiModels[a].toLocaleLowerCase().localeCompare(aiModels[b].toLocaleLowerCase()))}
              value={selectedAIModel}
              getOptionLabel={x => `${aiModels[x]}${extractVersion(x)}${appendModel(x)}`}
              onChange={(value) => setSelectedAIModel(value)}
              />
          )}
        </div>
      )}
      {showAdvancedFeature && (
        <div key="templates" className={"super-user ai-templates"}>
          <SimpleSelect
            className={"super-user template-select"}
            options={Object.keys(aiTemplates)}
            value={selectedTemplate}
            onChange={(value) => setSelectedTemplate(value)}
          />
          <textarea
            className={"template-editor"}
            onKeyDown={e => {
              if (e.key === "Escape") {
                setTemplateText(aiTemplates[selectedTemplate]);
              }
            }}
            onChange={e => setTemplateText(e.target.value)}
            value={templateText}
          />
        </div>
      )}
      {showAdvancedFeature && showSettings && searchParams && (
        <ChatSettings
          key={"settings"}
          onChange={setChatSettings}
          categories={categories}
          disabled={convoStarted}
          userStore={userStore}
          appStatusStore={appStatusStore}
        />
      )}
    </div>
  )
};

export default ChatSession;
