import {useEffect, useRef} from "react";

// NOTE: this only works where the SSE event type has not been set; for that you need to addEventListener for each type
export const useSSEListener = ({url, onMessage = null, onError = null, onOpen = null}) => {
  const mhRef = useRef(onMessage);
  const ehRef = useRef(onError);
  const ohRef = useRef(onOpen);

  useEffect(() => {
    mhRef.current = onMessage;
    ehRef.current = onError;
    ohRef.current = onOpen;
  }, [onMessage, onError, onOpen]);

  useEffect(() => {
    if (url) {
      const eventSource = new EventSource(url);
      eventSource.addEventListener("open", e => ohRef.current && ohRef.current(e));
      eventSource.addEventListener("message", e => mhRef.current && mhRef.current(e));
      eventSource.addEventListener("error", e => {
        ehRef.current && ehRef.current(e);
        if (e.readyState !== EventSource.CLOSED) {
          eventSource.close();
        }
      });
      return () => {
        eventSource.close();
      };
    }
  }, [url]);
}
